.App {
  text-align: center;
}

body {
  background-color: #f8f9fa;
  counter-reset: section;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.floating-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-bottom: 10px;
  padding-top: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.floating-widget-minimize {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-bottom: 10px;
  padding-top: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

header {
  height: 58px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  height: 55px;
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #d3d3d3;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.react-tabs {
  display: flex;
  margin-left: 36px;
  width: 470px;
  height: 400px;
  color: black;
  background: "white";
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 170px;
  margin: 0;
  padding: 0;
  color: black;
  background: white;
}

.react-tabs__tab {
  height: 70px;
  list-style: none;
  padding: 12px 6px 12px 24px;
  cursor: pointer;
  color: #bbb;
}

.react-tabs__tab--selected {
  background: "white";
  border-left: 4px solid #329da8;
  border-right: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
  color: "black";
  padding-left: 21px;
}

.react-tabs__tab-panel {
  display: none;
  width: 300px;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.panel-content {
  text-align: center;
}

.dropzone {
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 50px 0;
  text-align: center;
  text-transform: uppercase;
  color: black;
}

.dropzone.active {
  border-color: #fff;
}

.file {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  background-color: #41415d;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
  position: relative;
  overflow: hidden;
}

.file .progress {
  background-color: rgba(80, 80, 120, 0.8);
  position: absolute;
  inset: 0;
  width: 0;
  padding-top: 10px;
  text-align: center;
}

.file .progress.done {
  top: 100px;
}

.login-right-panel {
  background: transparent linear-gradient(0deg, #0099ab 100%, white 0%) 0% 0%
    no-repeat padding-box;
}

.login-form {
  height: 100vh;
  background: #ffffff 0% 0% no-repeat padding-box;

  opacity: 1;
  overflow: hidden;
}

.login-title {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.login-title-description {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #747a88;
}

.login-input-box {
  margin-left: 18px;
}

.login-box {
  height: 500px;
  width: 500px;
  border-radius: 20px !important;
}

.login-button {
  width: "400px";
  height: 48px;
  background: #454c66 !important;
  margin-left: inherit;
}

.login-system-title {
  font-family: "Arial-Rounded-MT", sans-serif;
  font-weight: bold;
  font-size: 40px;
}

.login-system-title-sub {
  color: #747a88;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 28px;
}

.login-system-title-helper {
  color: #747a88;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.login-bottom-design-img {
  position: fixed;
  bottom: 0px;
  left: -10px;
  width: 40rem;
}

.login-rdb-logo {
  width: 73.9px;
  height: 90px;
  margin-top: 50px;
}

.xgen-logo {
  margin-top: 50px;
}

.sample {
  margin-left: inherit;
}

.bold {
  font-weight: bold;
}

.basic-font {
  font-family: "Poppins", sans-serif !important;
}

.basic-font-regular {
  font-family: "Poppins-Regular", sans-serif !important;
}

.basic-font-color {
  color: #747a88 !important;
}

.basic-font-color-bold {
  color: #454c66 !important;
}

.font-italic {
  font-style: italic !important;
}

.font-size-8 {
  font-size: 8px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-28 {
  font-size: 28px;
}

.basic-opacity {
  opacity: 1;
}

.opacity-7 {
  opacity: 0.7;
}

.align-center {
  text-align: center;
  align-items: center;
}

.align-left {
  text-align: left;
  align-items: left;
}

.align-right {
  text-align: right;
  align-items: right;
}

.margin-sm {
  margin: 10px;
}

.margin-sm-t {
  margin-top: 10px;
}

.margin-sm-b {
  margin-bottom: 10px;
}

.margin-sm-l {
  margin-left: 10px;
}

.margin-sm-r {
  margin-right: 10px;
}

.margin-xs {
  margin: 5px;
}

.margin-xs-t {
  margin-top: 5px;
}

.margin-xs-b {
  margin-bottom: 5px;
}

.margin-xs-l {
  margin-left: 5px;
}

.margin-xs-r {
  margin-right: 5px;
}

.margin-m {
  margin: 15px;
}

.margin-m-t {
  margin-top: 15px;
}

.margin-m-b {
  margin-bottom: 15px;
}

.margin-m-l {
  margin-left: 15px;
}

.margin-m-r {
  margin-right: 15px;
}

.margin-l {
  margin: 20px;
}

.margin-l-t {
  margin-top: 20px;
}

.margin-l-b {
  margin-bottom: 20px;
}

.margin-l-l {
  margin-left: 20px;
}

.margin-l-r {
  margin-right: 20px;
}

.padding-sm {
  padding: 10px;
}

.padding-sm-t {
  padding-top: 10px;
}

.padding-sm-b {
  padding-bottom: 10px;
}

.padding-sm-l {
  padding-left: 10px;
}

.padding-sm-r {
  padding-right: 10px;
}

.padding-xs {
  padding: 5px;
}

.padding-xs-t {
  padding-top: 5px;
}

.padding-xs-b {
  padding-bottom: 5px;
}

.padding-xs-l {
  padding-left: 5px;
}

.padding-xs-r {
  padding-right: 5px;
}

.padding-m {
  padding: 15px;
}

.padding-m-t {
  padding-top: 15px;
}

.padding-m-b {
  padding-bottom: 15px;
}

.padding-m-l {
  padding-left: 15px;
}

.padding-m-r {
  padding-right: 15px;
}

.padding-l {
  padding: 20px;
}

.padding-l-t {
  padding-top: 20px;
}

.padding-l-b {
  padding-bottom: 20px;
}

.padding-l-l {
  padding-left: 20px;
}

.padding-l-r {
  padding-right: 20px;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.inner-full-height {
  height: calc(100vh - 155px);
  overflow-y: scroll;
  overflow-x: clip;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.error {
  color: red;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.display-flex {
  display: flex;
}

.text-align-center {
  text-align: center;
}

.table-margins {
  margin: 30px;
}

.table-column {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #231f20;
  text-align: left;
  opacity: 1;
}

.icon {
  position: absolute;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-circle {
  border-radius: 30px 30px 30px 30px;
  width: 60px;
  height: 60px;
  background: #4386be;
  opacity: 30%;
}

.calendar-icon {
  width: 2px;
  height: 2px;
}

.manage-app-title {
  font-size: 24px;
}

.manage-app-title-margins {
  margin-top: 20px;
  margin-left: 30px;
}

.manage-app-sub-title-margins {
  margin-top: 45px;
  margin-left: 30px;
}

.manage-app-date {
  font-size: 18px;
  margin-top: 5px;
  margin-left: 5px;
}

.manage-app-date-calendar {
  margin-left: 30px;
}

.manage-app-status-box {
  height: 100px;
  width: 300px;
  border-radius: 8px !important;
  box-shadow: 0px 10px 10px #747a880d;
  opacity: 1;
  margin-right: 30px;
  margin-left: 30px;
}

.manage-app-paper {
  width: 100%;
  height: 1090px;
  border-radius: 8px 8px 8px 8px;
  top: 318px;
  left: 30px;
  opacity: 1;
  box-shadow: 0px 10px 10px #747a880d;
}

.online-app-sub-title {
  margin-left: 30px;
}

.online-app-paper {
  width: 100%;
  height: 140px;

  margin-top: 25px;
}

.online-app-applicant-type-button {
  left: 60px;
  width: 150px;
  height: 48px;
  border: 0.5px solid #4386be;
  background: #4386be 0% 0% no-repeat padding-box;
  border-radius: 6px 0px 0px 6px;
  opacity: 1;
}

.online-app-applicant-type-button-unclicked {
  top: 251px;
  left: 300px;
  width: 150px;
  height: 48px;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  border: 0.5px solid #4386be;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px 0px 0px 6px;
  opacity: 1;
}

.online-app-nic-search {
  width: 90%;
  height: 48px;
  background: #f8f9fa 0% 0% no-repeat padding-box;
  border: 0.5px solid #ced4da;
  border-radius: 6px;
  opacity: 1;
}

.online-app-search-button {
  width: 150px;
  height: 48px;
  background: #4386be;
  border-radius: "0px 6px 6px 0px";
  opacity: 1;
  text-align: "center";
  font-family: "Poppins-Medium", sans-serif;
  font-size: "12px";
  letter-spacing: "0px";
  color: #ffffff;
}

.online-app-clear-button {
  width: 150px;
  height: 48px;
  border: 1px solid #e3e4e7;
  border-radius: 6px;
  opacity: 1;
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.online-app-applicant-result-paper {
  top: 358px;
  left: 30px;
  width: 100%;
  height: 692px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #747a880d;
  border-radius: 8px;
  opacity: 1;
  margin-left: 30px;
  margin-top: 30px;
}

.online-app-applicant-new-lead-button-div {
  margin-top: 30px;
}

.online-app-applicant-new-lead-button {
  background: #00ab71 0% 0% no-repeat padding-box;
  color: white !important;
  border-radius: 6px;
}

.app_screens--background {
  background: #f8f9fa;
}

.calculator_slider--amount {
  width: "250px";
}

.mainSection__heading--bold {
  text-align: left;
  font: Poppins, SemiBold, sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.mainSection__heading--participantName {
  text-align: left;
  font: Poppins, SemiBold, sans-serif;
  font-size: 18px;
  letter-spacing: 0px;
  color: #4e5261;
  opacity: 1;
}

.subSection__heading--bold {
  text-align: left;
  font: Poppins, SemiBold, sans-serif;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.subSection__heading--bold-lead {
  text-align: left;
  font: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.mainSection__heading--normal {
  font-family: "Poppins", sans-serif;
  text-align: left;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.mainSection__description {
  text-align: left;
  font: normal medium 16px/26px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #747a88;
  opacity: 1;
}

.template__panel--show {
  border-radius: 8px;
  padding: 10px;
  height: auto;
  overflow: hidden;
  background: #fff;
  margin: 15px;
  box-shadow: 0px 10px 10px #747a880d;
}

.template__panel--hide {
  border-radius: 8px;
  padding: 10px;
  height: 45px;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 15px;
  box-shadow: 0px 10px 10px #747a880d;
}

.inspection_template__panel--hide {
  border-radius: 8px;
  padding: 10px;
  height: 100px;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 15px;
  box-shadow: 0px 10px 10px #747a880d;
}

.template__panel--hide-low-height {
  border-radius: 8px;
  padding: 10px;
  height: 25px;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 15px;
  box-shadow: 0px 10px 10px #747a880d;
}

.text-box_basic {
  width: 100%;
  border: 0.5px solid #ced4da;
  background: #f8f9fa 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.number-input-basic {
  border: 0.5px solid #ced4da;
  background: #f8f9fa 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  max-width: 140px;
}

.number-input-basic.regular-factor-input-basic {
  width: 100% !important;
  max-width: 100% !important;
}

.text-box_basic--background-white {
  width: 300px;
  border: 0.5px solid #ffffff;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.select_basic {
  background: #f8f9fa 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.select_basic_for_auto {
  width: 300px;
  border-radius: 4px;
  opacity: 1;
}

.select_short {
  width: 255px;
  background: var(--unnamed-color-f8f9fa) 0% 0% no-repeat padding-box;
  border: 0.5px solid #ced4da;
  border-radius: 4px;
  opacity: 1;
}

.input-label-basic {
  text-align: left;
  font: normal 600 16px/2px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.input-label-small {
  text-align: left;
  font: normal 600 14px/1px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.input-label-small-responsive {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.mainSection__navigation--back {
  width: 150px;
  height: 48px;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
  border: 1px solid var(--unnamed-color-e3e4e7);
  border: 1px solid #e3e4e7;
  border-radius: 6px;
}

.mainSection__navigation--next {
  width: 150px;
  height: 48px;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: var(--unnamed-color-4386be) 0% 0% no-repeat padding-box;
  background: #4386be 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: none;
}

.mainSection_content--background {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #747a880d;
  border-radius: 0px 0px 8px 8px;
  opacity: 1;
}

.dropdown-menu {
  height: 80px;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.dropdown-menu li {
  display: inline-block;
  position: relative;
  width: 200px;
  line-height: 35px;
  text-decoration: none;
  background: #fff;
}

.dropdown-menu li:first-child {
  padding-top: 26px;
}

.dropdown-menu li a {
  display: inline-block;
  width: 200px;
  line-height: 35px;
  text-decoration: none;
}

.dropdown-menu li li a {
  font-size: 16px !important;
}

.dropdown-menu li:hover {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  cursor: pointer;
  z-index: 10;
}

.dropdown-menu ul {
  position: absolute;
  display: none;
}

.dropdown-menu li:hover ul ul {
  display: none;
}

.dropdown-menu li:hover ul {
  display: block;
}

.dropdown-menu li li:hover ul {
  margin-left: 200px;
  margin-top: -35px;
  display: block;
}

.nu-li {
  padding: 10px;
}

.dropdown-submenu {
  position: absolute;
  left: 0px;
  top: 0px;
}

.screen_title--basic {
  text-align: left;
  font: normal 600 24px/29px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.screen_description--basic {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0px;
  color: #747a88;
  opacity: 1;
}

.button-repayment {
  background: var(--unnamed-color-454c66) 0% 0% no-repeat padding-box;
  background: #454c66 0% 0% no-repeat padding-box;
  border-radius: 6px 0px 0px 6px;
  opacity: 1;
}

.calculator_slider--title {
  text-align: left;
  font: normal 600 12px/2px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.calculator_slider--title-dark {
  text-align: left;
  font: normal 600 12px/2px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.calculator_slider--box {
  width: 100%;
}

.calculator-slider {
  width: 90%;
  background: "#747A88";
}

.calculator_slider--values {
  text-align: left;
  font: normal 600 12px/16px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #4386be;
  opacity: 1;
}

.loan-terms-years {
  text-align: left;
  font: "Poppins", sans-serif;
  font-size: 12px;
  letter-spacing: 0px;
  color: #747a88;
  opacity: 1;
  font-weight: 500;
}

.application-manager_details--name {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #231f20;
  opacity: 1;
}

.application-manager_details--email {
  text-align: left;
  font: normal 15px/19px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #454c66;
  opacity: 1;
}

.application-manager_application-id {
  text-align: right;
  font: normal 15px/19px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #4386be;
  opacity: 1;
  text-decoration: underline;
  cursor: pointer;
}

.application-manager_application-reference {
  text-align: left;
  font: normal 15px/19px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #4386be;
  opacity: 1;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 15px;
}

.application-manager_application-dash {
  text-align: left;
  font: normal 15px/19px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #1b82d6;
  opacity: 1;
  cursor: pointer;
}

.application-manager_details--phone {
  text-align: left;
  font: medium 15px/19px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #747a88;
  opacity: 1;
}

.application-manager_table--cel {
  text-align: left;
  font: medium 15px/19px "Poppins", sans-serif;
  letter-spacing: 0px;
  color: #231f20;
  opacity: 1;
}

.text-align-left {
  text-align: left;
}

.circular-loading-icon {
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 100;
}

.row_list_item {
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 10px #747a880d;
}

.icon-basic {
  color: #4386be;
  margin: 2px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.enable-chip {
  margin: 5px;
  padding: 8px 20px;
  background-color: #39a21a;
  border-radius: 5px;
  color: #fff;
}

.disable-chip {
  margin: 5px;
  padding: 8px 20px;
  background-color: #e61515;
  border-radius: 5px;
  color: #fff;
}

.major-action-button {
  background: #454c66;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.action-button {
  background-color: #4386be !important;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.product-map-container {
  padding: 20px;
  border-radius: 8px !important;
}

.product-list-container {
  max-height: 220px;
  overflow-y: auto;
  padding: 5px;
}

.text-center {
  text-align: center;
}

.product-description-container {
  max-height: 220px;
  overflow-y: auto;
  padding: 5px;
  margin: 10px 0;
  background: #f8f9fa;
  border-radius: 4px;
}

.display-card--show {
  border-radius: 8px;
  padding: 5px;
  height: auto;
  overflow: hidden;
  background: #f8f9fa;
  margin: 15px;
}

.display-card--hide {
  border-radius: 8px;
  height: 45px;
  overflow: hidden;
  background: #f8f9fa;
  margin: 15px;
  padding: 10px;
}

.MuiTypography-root,
.MuiOutlinedInput-input,
.MuiInputBase-input,
.MuiInputLabel-root,
.MuiChip-label,
.MuiButton-root,
.MuiAutocomplete-popper,
.MuiAutocomplete-option,
.MuiAutocomplete-root,
.MuiMenuItem-root {
  font-family: "Poppins", sans-serif !important;
  text-transform: unset !important;
}

.modal-text-field .MuiOutlinedInput-input,
.modal-text-field .MuiInputBase-input {
  font-size: 14px;
  color: #747a88;
}

.main-bar .MuiButtonBase-root.MuiTab-root.Mui-selected,
.inner-bar .MuiButtonBase-root.MuiTab-root.Mui-selected {
  background-color: rgb(17 114 206 / 10%);
}

.main-bar .MuiTabs-indicator {
  height: 3px !important;
}

.inner-bar .MuiTabs-indicator {
  width: 3px !important;
}

.approve-chip {
  margin: 5px;
  padding: 8px 20px;
  background-color: #0fb079;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.reject-chip {
  margin: 5px;
  padding: 8px 20px;
  background-color: #ff0101;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.document-upload-wrapper-text {
  font-weight: bold;
}

.green-font {
  color: #00ab28;
}

.red-font {
  color: red;
}

.yellow-font {
  color: rgb(233, 146, 6);
}

.container {
  position: relative;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cm-template-item:hover {
  background-color: #b5b5b53b;
}

.light-gray-bg {
  background-color: #b5b5b53b !important;
}

.label {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 5px;
  background-color: #e8e8e8;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 700;
  -webkit-transition: background 0.1s ease;
  transition: background 0.1s ease;
  border-radius: 5px;
  min-width: 80px;
}

.label-revert {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 5px;
  background-color: #e8e8e8;
  background-image: none;
  padding: 0.25em 0.9em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 700;
  -webkit-transition: background 0.1s ease;
  transition: background 0.1s ease;
  border-radius: 5px;
  min-width: 80px;
}

.btn-label {
  display: inline-block;
  line-height: 1.3 !important;
  vertical-align: baseline;
  margin: 0 3px;
  background-color: #e8e8e8;
  background-image: none;
  padding: 0.2833em 0.533em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 700;
  -webkit-transition: background 0.1s ease;
  transition: background 0.1s ease;
  border-radius: 0px;
  min-width: 80px;
  cursor: pointer;
}

.btn-label:disabled {
  opacity: 0.5;
}

.enable-label {
  background-color: #39a21a1c;
  color: #39a21a;
  border: 2px solid #39a21a;
}

.disable-label {
  background-color: #ff00001c;
  color: #ff0000;
  border: 2px solid #ff0000;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-600 {
  font-weight: 600;
}

.field-item-card {
  background-color: #686666;
  color: white;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #686666;
}

.field-item-card:hover {
  background-color: white;
  color: #686666;
  border: 1px solid #686666;
}

.menu-container-useref {
  position: relative;
}

.menu-useref {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu-useref.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu-useref ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-useref li {
  border-bottom: 1px solid #dddddd;
}

.menu-useref li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.menu-trigger {
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}

.menu-trigger img {
  border-radius: 90px;
}

.navbar-mega-menu {
  overflow: hidden;
  background-color: white;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.navbar-mega-menu a {
  float: left;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
}

.btn {
  &:hover {
    color: #a3cc44;
  }
}

.appsearch-content {
  display: none;
}

.appsearch:hover + .appsearch-content {
  display: flex;
}

.dropdown-mega-menu {
  float: left;
  overflow: hidden;
  cursor: pointer;
}

.navMainMenu {
  cursor: pointer;
  color: gray;
  text-align: center;
  padding: 8px 16px !important;

  &:hover {
    color: #4386be !important;
    transform: scale(1.05);
  }
}

.activeNavMainMenu {
  color: #4386be !important;
}

.dropdown-mega-menu .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #747a88;
  padding: 6px 16px;
  background-color: inherit;
  margin: 0;
  cursor: "pointer";
}

.dropdown-mega-menu-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 60%;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10000;
}

.dropdown-mega-menu:hover .dropdown-mega-menu-content {
  display: block;
}

.display-block {
  display: block;
}

.column-mega-menu {
  float: left;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  color: #454c66;
  width: 25%;
  padding: 10px;
  background-color: white;
  height: 230px;
  box-shadow: "none";
}

.column-mega-menu .btn-clickable-mega-menu {
  float: none;
  color: #747a88;
  padding: 5px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.row-mega-menu:after {
  content: "";
  clear: both;
}

hr.mega-menu-hr {
  width: 10%;
  margin: 0;
  border-top: 2px solid #4386be;
  border-radius: 5px;
}

.mega-menu-sub-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

@media screen and (max-width: 600px) {
  .column-mega-menu {
    width: 100%;
    height: auto;
  }
}

.font-green {
  color: green;
}

.fund-release-chip {
  margin-right: 5px;
  padding: 8px 20px;
  background-color: #9aaca6;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.fund-release-chip:hover {
  background-color: #32574a;
  color: #fff;
}

.fund-release-bg-1 {
  color: #1cd596;
  background-color: #1cd5963d;
}

.fund-release-bg-2 {
  color: #ffb55f;
  background-color: #ffb55f3d;
}

.fund-release-bg-3 {
  color: #1cd596;
  background-color: #1cd5963d;
}

.fund-release-bg-4 {
  color: #ff9090;
  background-color: #ff90903d;
}

.fund-release-bg-5 {
  color: #ff9090;
  background-color: #ff90903d;
}

.dot {
  height: 40px;
  width: 40px;
  background-color: #bbb;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}

.disabled {
  cursor: not-allowed;
}

.privilege-hide {
  display: none !important;
}

.privilege-disable {
  opacity: 0.65 !important;
  cursor: not-allowed !important;
  pointer-events: none;
}

.error-credit {
  color: #ff0000 !important;
}

.warning-credit {
  color: #ffa500 !important;
}

.blue-font-color {
  color: #0051ca !important;
}

.trail-calculate-select {
  color: #0051ca !important;
  font-weight: 200 !important;
}

.trail-calculate-input {
  border: 1px solid #cdd6df;
  color: #0051ca !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  padding: 8.5px 14px;
  background-color: #e6f0fa;
  text-align: end;
}

.sales-calculate-input {
  border: 1px solid #cdd6df;
  color: #0051ca !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  padding: 8.5px 14px;
  background-color: #e6f0fa;
  text-align: end;
}

.data-box {
  border: 1px solid #e6f0fa;
  margin: 5px 0 !important;
  border-radius: 5px;
}

.red {
  color: red;
}

.no-prt-msg {
  color: gray;
  font-size: 16px;
  padding-top: 10%;
  padding-left: 15%;
}

.align-self-center {
  align-self: center;
}

.vertical-tab-height {
  height: calc(100vh - 310px);
}

.inner-component-height {
  height: calc(100% - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: center;
  width: 100%;
}

.inner-component-height-left {
  height: 450px;
  overflow: auto;
  justify-content: left;
  width: 100%;
}

.inner-component-full-height {
  height: 100%;
  overflow-y: auto;
}

.tab-paper {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.sub-header-height {
  height: 40px;
}

.securityWrapper {
  border: 1px solid grey;
  border-radius: 10px;
}

.mainSectionWrapper {
  border: 1px solid #e6f0fa;
  padding: 1px;
}

.grey-bold-text {
  color: #737373;
  font-weight: 700;
}

.credit-preview--grid-item {
  min-height: 48px;
  display: flex;
  align-items: center;
}

.credit-preview--header-more-link {
  color: #939494 !important;
  min-width: 0 !important;
  padding: 0px !important;
  background: none !important;
  margin-top: -8px !important;
  font-size: 10px !important;
}

.credit-preview--header-personal {
  display: flex;
  flex-wrap: nowrap !important;
}

.credit-preview--scv-small-text {
  font-size: 9px !important;
}

.credit-preview--prev-borrow {
  font-size: 9px !important;
}

.credit-preview--grid-item .prev-borrow-data {
  padding: 4px !important;
}

.credit-tab--applicant-verify span {
  padding-left: 0px !important;
}

.securitydoc_upperCaseText {
  text-transform: uppercase !important;
}

.securitydoc_cell {
  line-height: 2rem !important;
}

.securitydoc_textrow {
  padding-top: 10px;
  padding-bottom: 10px;
}

.securitydoc_contentspace-left {
  padding-right: 2% !important;
}

.securitydoc_contentspace-right {
  padding-left: 2% !important;
}

.justify-main-content-column {
  text-align: justify;
}

.securitydoc-sign-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
}

.securitydoc-sign-container:last-child {
  margin-bottom: 0px;
}

.securitydoc-sign-container .securitydoc-sign-column-l {
  flex-basis: 95%;
  display: flex;
  flex-wrap: wrap;
  text-align: left !important;
}

.securitydoc-sign-container .securitydoc-sign-column-r {
  flex-basis: 1%;
  display: flex;
  flex-wrap: wrap;
  border-top: 2px solid #000000;
  border-right: 2px solid #000000;
  border-bottom: 2px solid #000000;
  position: relative;
}

.securitydoc-sign-container .securitydoc-sign-column-r::after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 8px;
  background: #000000;
}

.securitydoc-sign-container .securitydoc-sign-column-witnesses {
  flex-basis: 95%;
}

.securitydoc-sign {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7px;
}

.securitydoc-sign.above-name-sign {
  margin-bottom: 15px;
}

.securitydoc-sign.above-name-sign-blank {
  margin-top: 15px;
}

.securitydoc-sign .left-column {
  flex-basis: 35%;
}

.securitydoc-sign .right-column {
  flex-basis: 35%;
}

.securitydoc-sign span.year-type {
  margin-right: 5px;
}

.securitydoc_centerText {
  text-align: center;
}

.submittedapps_status_active {
  color: #4386be;
}

.submittedapps_status_reverted {
  color: red;
}

.submittedapps_status_reverted_count {
  color: grey;
  font-size: 12px;
}

.tablerow_reverted {
  border-bottom: 0.125rem solid red !important;
}

.approving-cond-custom-btns {
  margin-right: 8px !important;
}

.stage-manage-ui-jobs {
  border: 1px solid grey;
  border-radius: 8px;
}

.loan-agreement-subtitles {
  text-transform: uppercase;
}

.loan-agreement-date {
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  align-content: center;
  height: 50px;
}

.onbehalf-of-bank {
  text-transform: uppercase;
}

.active-control-tab-arrows {
  display: inline-block;
  margin-right: 15px;
}

.crib-record-id-dropdown-container {
  padding-top: 41px !important;
}

.inner-content-column {
  max-width: 100%;
  overflow: hidden;
}

.security-item-devider {
  margin-top: 25px !important;
  margin-bottom: 25px;
}

.typable-text-item {
  display: inline-flex;
  flex-wrap: wrap;
  min-width: 50px;
  align-content: center;
  justify-content: center;
  height: 32px;
  padding: 0.5px 10px 0.5px 10px;
  border-radius: 20px;
  background: #a3cef7;
  color: #000000;
}

.view-product-toggle div {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
}

.promissory-note-body {
  text-align: justify;
}

.loan-receipt-branch-details {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
}

.loan-receipt-branch-details-l {
  flex-basis: 70%;
}

.loan-receipt-branch-details-r {
  flex-basis: 30%;
}

.loan-receipt-signature-l {
  width: 50%;
}

.loan-receipt-signature-r {
  width: 50%;
}

.loan-receipt-witness {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.loan-receipt-witness div {
  display: flex;
  flex-wrap: wrap;
  padding-right: 1.5%;
  flex-basis: 30%;
}

.sec-document-uppercase-heading {
  text-transform: uppercase;
}

.letter-setoff-bold-text {
  font-weight: bold;
  color: rgba(0, 0, 0, 1) !important;
}

.letter-setoff-wrapper {
  text-align: justify;
}

.pa-full-width {
  flex-basis: 100%;
}

.gurantee-bond-sub-text {
  text-align: center;
  margin-top: 15px;
}

.offer-letter-undelined-heading {
  text-decoration: underline;
}

.offer-letter-condition-container li {
  margin-bottom: 15px;
  text-align: justify;
}

.ol-manager-signature-placeholder {
  margin-top: 40px;
}

.signature-verification-header-label {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.signature-verification-signature-placeholder {
  margin-bottom: 25px;
}

.signature-verification-signature-label {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-transform: "";
}

.case-none {
  text-transform: none !important;
}

.case-upper {
  text-transform: uppercase !important;
}

.case-lower {
  text-transform: lowercase !important;
}

.case-proper {
  text-transform: capitalize !important;
}

.letter-upper:first-letter {
  text-transform: uppercase !important;
}

.letter-lower:first-letter {
  text-transform: lowercase !important;
}

.user-preference-label {
  font-size: 12px;
  font-weight: normal;
}

.case-sentence {
  text-transform: lowercase !important;
}

.case-sentence::first-letter {
  text-transform: uppercase !important;
}

span.security-doc-type-variable {
  display: inline;
  vertical-align: top;
}

.lr-here-by {
  margin-left: 6px;
}

.security-documents-table {
  font-size: unset !important;
  font-family: unset !important;
  font-weight: unset !important;
}

.loan-terms {
  margin-bottom: 0px !important;
}

.loan-agreement-condition-list {
  padding: 0px !important;
  padding-left: 32px !important;
}

.set-signature-spaces {
  width: 48%;
  padding-left: 2%;
}

.gurantee-bond-name-address {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.gurantee-bond-name-address .left-col {
  flex-basis: 5%;
}

.gurantee-bond-name-address .right-col {
  flex-basis: 90%;
  padding-left: 6px;
}

.cell-inner-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.cell-inner-container .left-col {
  flex-basis: 5%;
}

.cell-inner-container .right-col {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 90%;
  padding-left: 6px;
}

.cell-inner-container .right-col .list-item {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
}

.cell-inner-container .right-col .list-item .list-item-number {
  flex-basis: 5%;
}

.cell-inner-container .right-col .list-item .list-item-content {
  flex-basis: 90%;
  padding-left: 6px;
}

.list-marker {
  margin-right: 6px;
}

.offer-letter-condition-list {
  margin-bottom: 0px;
}

.application-summary-key {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  padding: 10px;
  background-color: lightgray;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

.application-summary-value {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

.application-summary-main-title {
  background-color: black;
  color: whitesmoke;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.application-summary-title {
  background: transparent linear-gradient(0deg, #0099ab 100%, white 0%) 0% 0%
    no-repeat padding-box;
  color: whitesmoke;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.application-summary-sub-title {
  background-color: gray;
  color: whitesmoke;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.application-summary-los-main-title {
  background-color: #990000;
  color: whitesmoke;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.application-summary-los-title {
  background: transparent linear-gradient(0deg, #135dab 100%, white 0%) 0% 0%
    no-repeat padding-box;
  color: whitesmoke;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.break-point-marker {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.watch-list-item {
  border-radius: 8px;
}

.prior-request-meta {
  background: #d9edf7;
  border: 1px solid #9ec4ea;
}

.prior-approve-meta {
  background: #d2e9cd;
  border: 1px solid #98ce89;
}

.risk-legal-insurance-document-view-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 205.83px;
  height: 127.67px;
}

@media (max-width: 1024px) {
  .credit-preview--header-personal {
    flex-wrap: wrap !important;
  }

  .credit-preview--header-status-text {
    font-size: 11px !important;
  }

  .credit-preview--header-pic {
    width: 60px !important;
    height: 60px !important;
  }
}

.assets-data-tableBody-class {
  border: 2px solid #d3d3d3;
}

.close-btn {
  width: 12px;
  height: 12px;
  color: #ff1b1b;
  background: #ff1b1b3d;
  border-radius: 30px;
  padding: 9px 10px;
}

.add-btn {
  width: 12px;
  height: 12px;
  color: #39a21a;
  background: #39a21a3d;
  border-radius: 30px;
  padding: 9px 10px;
}

@media print {
  .typable-text-item {
    background: none !important;
    padding: 0px !important;
  }

  body {
    position: relative;
    orphans: 7;
  }

  .break-point-marker {
    display: none !important;
  }

  #content {
    margin-right: 5mm;
    padding-right: 5mm;
  }
}

.iframe-styles {
  width: 100%;
  height: calc(100vh - 100px);
  border: none;
}

.timer-label {
  scale: 1;
  color: gray;
}

.timer-value-large {
  color: #00ab71;
}

.credit-header-btn-active {
  background-color: "#e6f0fa";
}

.credit-header-button {
  border: "none";
  padding: "8px";
  background-color: "white";
}

.multiple-input-padding .MuiSelect-select {
  padding: 8px !important;
}

.credit-header-btn {
  cursor: pointer;
}

.placeholder-heading:last-of-type {
  margin-left: auto;
}

.add-icon-hide {
  display: none !important;
}

div::-webkit-scrollbar {
  width: 12px;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.MuiAlert-message {
  width: 100%;
}

.MuiFormLabel-asterisk {
  color: red;
}

.application-form-navigation-stepper-container {
  background-color: #cacece;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #a0a5a5;
  }

  &:active {
    background-color: #cacece;
  }
}

.chanage-detector {
  padding: 8px;
  color: white;

  .normal {
    background-color: rgba(128, 128, 128, 0.5);
    border-radius: 10px;

    .chanage-detector-icon {
      background-color: rgb(128, 128, 128);
      border-radius: 10px;
    }

    .text {
      font-weight: bold;
    }
  }

  .minus {
    background-color: rgba(255, 0, 0, 0.4);
    border-radius: 10px;

    .chanage-detector-icon {
      background-color: rgba(255, 0, 0);
      border-radius: 10px;
    }

    .text {
      font-weight: bold;
      color: red;
    }
  }

  .plus {
    background-color: rgba(0, 128, 0, 0.4);
    border-radius: 10px;

    .chanage-detector-icon {
      background-color: rgba(0, 128, 0);
      border-radius: 10px;
    }

    .text {
      font-weight: bold;
      color: green;
    }
  }

  .input-right * {
    padding-right: 10px;
    text-align: end !important;
  }
}

.disabled-container {
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
}

.text-btn {
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #4386be;
    /* scale: 1.05; */
  }
  &:active {
    color: #53be43;
  }
}

.active-stack {
  background-color: #7374761e !important;
}
